/**
 * /* https://github.com/twbs/bootstrap/blob/v4.0.0-beta/dist/css/bootstrap-reboot.css
 *
 * @format
 */

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap');

html {
  box-sizing: border-box;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
    }

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
}


@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

@-ms-viewport {
  width: device-width;
}

/* Handle print */
@media print {

  html {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important; /* Moz */
  }

  body.toPrint {
    overflow: hidden !important;
  }
  body.toPrint #root {
    visibility: hidden;
    overflow: hidden !important;
    height: 0px;
  }
  body.toPrint #root * {
    visibility: hidden;
    transition: none !important;
    position: static;
    z-index: 0;
  }
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  font-family: MontSerrat, Arial, Arial, Helvetica, sans-serif;
  line-height: 1.5;
  color: #55565A;
  background-color: #fff;
  z-index: 0;
  overflow-x: hidden;
}



#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}


[tabindex='-1']:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre, code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a, area, button, [role='button'], input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input[type= "file"] {
  cursor: pointer!important;
  font-size: 0;
	opacity: 0;
	overflow: hidden!important;
	position: absolute!important;
  z-index: 1!important;
  width: 100%!important;
  height: 100%!important;
  top: 0;
  left: 0;

}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: block!important;
}

label::first-letter {
  text-transform: capitalize!important;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

form {
  width: 100%;
}

button, html [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type='button']::-moz-focus-inner, [type='reset']::-moz-focus-inner, [type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'], input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'], input[type='time'], input[type='datetime-local'], input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}


legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button, [type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.rc-slider-tooltip-inner {
  background-color: transparent!important;
  box-shadow: none!important;
  padding: 0px!important;
  margin-top: 25px!important;
  height: 0px!important;
  color: #0099A8!important;
  min-width: 70px!important;
  font-size: .8rem!important;
  font-weight: 700;
}


.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  display: none;
}

 #root ~ div {
  /* top: auto!important; */
  z-index: 2;
  transform: origin(initial);
}

div.scrollRef {
  scroll-margin-block-start: 10.85em
}


@media(min-width: 500px) {
div.scrollRef {
  scroll-margin-block-start: 8.1em
}
}

@media(min-width: 768px) {
  div.scrollRef {
    scroll-margin-block-start: 21em
  }
  }

.section-fade-in {
  animation: sectionFadeIn ease 1.5s;
  -webkit-animation: sectionFadeIn ease 1.5s;
  -moz-animation: sectionFadeIn ease 1.5s;
  -o-animation: sectionFadeIn ease 1.5s;
  -ms-animation: sectionFadeIn ease 1.5s;
}


@keyframes sectionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes sectionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes sectionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes sectionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes sectionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



/*# sourceMappingURL=bootstrap-reboot.css.map */


@font-face {
  font-family: 'Socicon';
  src:  url('fonts/Socicon.eot');
  src:  url('fonts/Socicon.eot') format('embedded-opentype'),
    url('fonts/Socicon.woff2') format('woff2'),
    url('fonts/Socicon.ttf') format('truetype'),
    url('fonts/Socicon.woff') format('woff'),
    url('fonts/Socicon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
