@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap);
/**
 * /* https://github.com/twbs/bootstrap/blob/v4.0.0-beta/dist/css/bootstrap-reboot.css
 *
 * @format
 */

html {
  box-sizing: border-box;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
    }

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
}


@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

@-ms-viewport {
  width: device-width;
}

/* Handle print */
@media print {

  html {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important; /* Moz */
  }

  body.toPrint {
    overflow: hidden !important;
  }
  body.toPrint #root {
    visibility: hidden;
    overflow: hidden !important;
    height: 0px;
  }
  body.toPrint #root * {
    visibility: hidden;
    transition: none !important;
    position: static;
    z-index: 0;
  }
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  font-family: MontSerrat, Arial, Arial, Helvetica, sans-serif;
  line-height: 1.5;
  color: #55565A;
  background-color: #fff;
  z-index: 0;
  overflow-x: hidden;
}



#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}


[tabindex='-1']:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre, code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a, area, button, [role='button'], input, label, select, summary, textarea {
  touch-action: manipulation;
}

input[type= "file"] {
  cursor: pointer!important;
  font-size: 0;
	opacity: 0;
	overflow: hidden!important;
	position: absolute!important;
  z-index: 1!important;
  width: 100%!important;
  height: 100%!important;
  top: 0;
  left: 0;

}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: block!important;
}

label::first-letter {
  text-transform: capitalize!important;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

form {
  width: 100%;
}

button, html [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type='button']::-moz-focus-inner, [type='reset']::-moz-focus-inner, [type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'], input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'], input[type='time'], input[type='datetime-local'], input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}


legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button, [type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.rc-slider-tooltip-inner {
  background-color: transparent!important;
  box-shadow: none!important;
  padding: 0px!important;
  margin-top: 25px!important;
  height: 0px!important;
  color: #0099A8!important;
  min-width: 70px!important;
  font-size: .8rem!important;
  font-weight: 700;
}


.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  display: none;
}

 #root ~ div {
  /* top: auto!important; */
  z-index: 2;
  -webkit-transform: origin(none);
          transform: origin(none);
  -webkit-transform: origin(initial);
          transform: origin(initial);
}

div.scrollRef {
  scroll-margin-block-start: 10.85em
}


@media(min-width: 500px) {
div.scrollRef {
  scroll-margin-block-start: 8.1em
}
}

@media(min-width: 768px) {
  div.scrollRef {
    scroll-margin-block-start: 21em
  }
  }

.section-fade-in {
  animation: sectionFadeIn ease 1.5s;
  -webkit-animation: sectionFadeIn ease 1.5s;
  -moz-animation: sectionFadeIn ease 1.5s;
  -o-animation: sectionFadeIn ease 1.5s;
  -ms-animation: sectionFadeIn ease 1.5s;
}


@keyframes sectionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes sectionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



/*# sourceMappingURL=bootstrap-reboot.css.map */


@font-face {
  font-family: 'Socicon';
  src:  url(/static/media/Socicon.3dc7e83d.eot);
  src:  url(/static/media/Socicon.3dc7e83d.eot) format('embedded-opentype'),
    url(/static/media/Socicon.24eeff2e.woff2) format('woff2'),
    url(/static/media/Socicon.d650a33e.ttf) format('truetype'),
    url(/static/media/Socicon.33811ce5.woff) format('woff'),
    url(/static/media/Socicon.0ae38b31.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}


/* Slideshow container */
.Carousel_slideshowContainer__1KLi1 {
  position: relative;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.Carousel_slidesWrapper__3sSeB {
  flex: 1 1;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
}

div.Carousel_arrowContainer__284kj {
  z-index: 1;
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 0px;
}

div.Carousel_arrowContainerLeft__2A60k {
  left: 0;
}

div.Carousel_arrowContainerRight__2npMy {
  right: 0;
}

i.Carousel_arrow__P4HVF {
  border: solid #D9D9D9;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  cursor: pointer;
  position: absolute;
  outline: none;
  margin-right: 12px;
  margin-left: 12px;
  top: calc(50% - 2rem);
  width: auto;
  transition: 0.6s ease;
  z-index: 1;
  mix-blend-mode: multiply;
}

@media screen and (min-width: 768px) {
  i.Carousel_arrow__P4HVF {
    padding: 7px;
    margin-right: 44px;
    margin-left: 44px;
  }
  div.Carousel_arrowContainer__284kj {
    width: 55px;
  }
}

i.Carousel_arrow__P4HVF.Carousel_arrowRight__3ky8c {
  right: 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

i.Carousel_arrow__P4HVF.Carousel_arrowLeft__3tDRo {
  left: 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.Carousel_slideshowContainer__1KLi1:hover i.Carousel_arrow__P4HVF.Carousel_arrowLeft__3tDRo,
.Carousel_slideshowContainer__1KLi1:hover i.Carousel_arrow__P4HVF.Carousel_arrowRight__3ky8c,
i.Carousel_arrow__P4HVF.Carousel_arrowLeft__3tDRo:focus,
i.Carousel_arrow__P4HVF.Carousel_arrowRight__3ky8c:focus {
  visibility: visible;
  opacity: 1;
}

@media (hover: none) {
  i.Carousel_arrow__P4HVF.Carousel_arrowRight__3ky8c,
  i.Carousel_arrow__P4HVF.Carousel_arrowLeft__3tDRo {
    visibility: visible;
    opacity: 1;
  }
}

/* The dots/bullets/indicators */
.Carousel_dots__2Cbgv {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.Carousel_dotsFixedTexts__3ZKQq {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
  padding: 4rem 0 0 0;
}

.Carousel_dot__19M74 {
  height: 0.8rem;
  width: 0.8rem;
  margin: 0 0.25rem;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

@media screen and (min-width: 768px) {
  .Carousel_dot__19M74 {
    height: 1rem;
    width: 1rem;
    margin: 0 0.35rem;
  }
}

[data-braintree-id="toggle"] {
  font-weight: 500;
  background-color: transparent;
  padding-top: 10px;
  color: rgb(85, 86, 90);
}

[data-braintree-id="toggle"], [data-braintree-id="toggle"]:hover {
  background-color: transparent;
}

[data-braintree-id="card"] {
  border-radius: 0;
}

[data-braintree-id="payment-options-container"],
[data-braintree-id="payment-options-container"] > *:first-child,
[data-braintree-id="payment-options-container"] > *:last-child {
  border-radius: 0;
  background-color: #fff;
}

[data-braintree-id="payment-options-container"] * {
  color: rgb(85, 86, 90) !important;
  font-weight: 500;
  outline: none !important;
}

[data-braintree-id="payment-options-container"] *:hover {
  border-color: rgb(0, 153, 168);
  background-color: rgba(0, 153, 168, 0.15);
}

[data-braintree-id="payment-options-container"] .braintree-option__label {
  background-color: transparent;
}

[data-braintree-id="payment-options-container"].braintree-options-list {
  padding: 0;
}

[data-braintree-id="payment-options-container"] > *:first-child {
  border-bottom-width: 1px;
  border-top-width: 1.5px;
  border-left-width: 1.5px;
  border-right-width: 1.5px;
}

[data-braintree-id="payment-options-container"] > *:first-child:hover {
  border-bottom-width: 1px;
}

[data-braintree-id="payment-options-container"] > *:first-child:hover ~ * {
  border-top-width: 0;
}

[data-braintree-id="payment-options-container"] > *:last-child {
  border-top-width: 1px;
  border-left-width: 1.5px;
  border-right-width: 1.5px;
}

[data-braintree-id="methods-container"] > * {
  border-radius: 0 !important;
}

[data-braintree-id="methods-container"].braintree-methods--active .braintree-method--active, 
[data-braintree-id="methods-container"].braintree-methods--active .braintree-method--active:focus {
  border-color: rgb(0, 153, 168);
  background-color: rgba(0, 153, 168, 0.15);
  border-color: rgb(0, 153, 168) !important;
  outline: none !important;
}

[data-braintree-id="methods-container"] .braintree-method .braintree-method__label {
  color: rgb(85, 86, 90);
}

[data-braintree-id="card-sheet-header"] .braintree-sheet__text {
  font-weight: 500;
  color: rgb(85, 86, 90);
}

[data-braintree-id="number-field-group"] {
  margin-bottom: 18px !important;
}

[data-braintree-id="card"] .braintree-sheet__content--form {
  padding-top: 18px;
  padding-bottom: 15px;
}

[data-braintree-id="number-field-group"] .braintree-form-number,
[data-braintree-id="expiration-date-field-group"] .braintree-form-expiration,
[data-braintree-id="cvv-field-group"] .braintree-form-cvv {
  border-color: #8A8A8D !important;
}

[data-braintree-id="number-field-group"].braintree-form__field-group--has-error .braintree-form-number,
[data-braintree-id="expiration-date-field-group"].braintree-form__field-group--has-error .braintree-form-expiration,
[data-braintree-id="cvv-field-group"].braintree-form__field-group--has-error .braintree-form-cvv {
  border-color: #DD0031 !important;
}

[data-braintree-id="number-field-error"],
[data-braintree-id="expiration-date-field-error"],
[data-braintree-id="cvv-field-error"] {
  color: #DD0031 !important;
}

[data-braintree-id="paypal"] {
  border-radius: 0;
}

[data-braintree-id="paypal-sheet-header"] .braintree-sheet__label {
  font-weight: 500;
  color: rgb(85, 86, 90);
}

[data-braintree-id="choose-a-way-to-pay"],
[data-braintree-id="wrapper"] .braintree-placeholder {
  display: none;
}

[data-braintree-id="sheet-container"]  {
  margin: 0;
}

.TextEditor_editorWrapper__1h_px {
  border: 1px solid #ECECEC;
}

.TextEditor_editorToolbar__1saTw {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #ECECEC;
  margin-bottom: 0;
}

.TextEditor_textEditor__1Z45a {
  margin: 0 10px;
}

.TextEditor_editorImagePopup__3tENN > * {
  position: relative;
}

.TextEditor_textEditor__1Z45a img {
  max-width: 100%;
  object-fit: contain;
}

.TextEditor_editorReadOnly__3Ge7N.TextEditor_editorWrapper__1h_px {
  border: none;
}

.TextEditor_editorReadOnly__3Ge7N .TextEditor_textEditor__1Z45a {
  margin: 0;
}
